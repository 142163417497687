import AsyncComputed from 'vue-async-computed'
import {
  AlertPlugin,
  BreadcrumbPlugin,
  ButtonPlugin,
  CardPlugin,
  LayoutPlugin,
  PaginationPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  NavbarPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormDatepickerPlugin,
  FormSelectPlugin,
  FormCheckboxPlugin,
  InputGroupPlugin,
  CollapsePlugin,
  IconsPlugin,
  BadgePlugin,
  FormRadioPlugin,
  TooltipPlugin,
  ListGroupPlugin,
  ButtonGroupPlugin,
  ModalPlugin,
  BLink
} from 'bootstrap-vue'

import Meta from 'vue-meta'
import pMap from 'p-map'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { PATH_PREFIX, HISTORY_MODE } from './config'
import { fetchUri, getCatalogUrl } from './util'
import { auth } from './store/auth.module'

const Catalog = () => import(/* webpackChunkName: "catalog" */ './components/Catalog.vue')
const Collection = () => import(/* webpackChunkName: "collection" */ './components/Collection.vue')
const Item = () => import(/* webpackChunkName: "item" */ './components/Item.vue')
const Login = () => import(/* webpackChunkName: "login" */ './components/Login.vue')
const Viewer = () => import(/* webpackChunkName: "viewer" */ './components/Viewer.vue')

Vue.use(AsyncComputed)
Vue.use(AlertPlugin)
Vue.use(BreadcrumbPlugin)
Vue.use(ButtonPlugin)
Vue.use(CardPlugin)
Vue.use(CollapsePlugin)
Vue.use(LayoutPlugin)
Vue.use(PaginationPlugin)
Vue.use(SpinnerPlugin)
Vue.use(TablePlugin)
Vue.use(FormRadioPlugin)
Vue.use(TabsPlugin)
Vue.use(NavbarPlugin)
Vue.use(FormPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormSelectPlugin)
Vue.use(IconsPlugin)
Vue.use(Meta)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(BadgePlugin)
Vue.use(TooltipPlugin)
Vue.use(ListGroupPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(FormCheckboxPlugin)
Vue.component('b-link', BLink)
const main = async () => {
  let persistedState = {}
  const renderedState = document.querySelector("script.state[type='application/json']")

  if (renderedState != null) {
    try {
      persistedState = JSON.URL(renderedState.text)
    } catch (err) {
      console.warn('Unable to parse rendered state:', err)
    }
  }

  const routes = [
    {
      path: '/logout',
      component: {
        beforeRouteEnter(to, from, next) {
          store.dispatch('auth/logout')
          router.push('/login')
        }
      },
      redirect: '/login'
    },
    {
      path: '/login',
      component: Login,
      props: (route) => {
        const ancestors = [getCatalogUrl(route)]

        return {
          ancestors,
          path: route.path,
          url: ancestors.slice(-1).pop()
        }
      }
    },
    {
      path: '/viewer',
      component: Viewer,
      props: (route) => {
        return {}
      }
    },
    {
      path: '/browse/collections/:collectionID/items/:itemID',
      name: 'item',
      component: Item,
      beforeEnter: async (to, from, next) => {
        const urls = [
          `${getCatalogUrl(to)}/collections/${to.params.collectionID}/items/${to.params.itemID}`,
          `${getCatalogUrl(to)}/collections/${to.params.collectionID}`
        ]
        await pMap(urls, store.dispatch.bind(store, 'load'), {
          concurrency: 10
        })
        return next()
      },
      props: (route) => {
        const ancestors = [getCatalogUrl(route)]

        const collectionID = route.params.collectionID
        const itemID = route.params.itemID

        let center = null

        if (route.hash) {
          center = route.hash.slice(1).split('/')
        }

        const url = `${getCatalogUrl(route)}/collections/${collectionID}/items/${itemID}`

        return {
          ancestors,
          center,
          collectionID,
          itemID,
          sourceID: '',
          path: route.path,
          url,
          endpoint: getCatalogUrl(route)
        }
      }
    },
    {
      path: '/browse/collections/:collectionID',
      redirect: '/browse/collections/:collectionID/items'
    },
    {
      path: '/browse/collections/:collectionID/items',
      component: Collection,
      name: 'items',
      beforeEnter: async (to, from, next) => {

        const urls = [
          `${getCatalogUrl(to)}/collections/${to.params.collectionID}`
        ]
        await pMap(urls, store.dispatch.bind(store, 'load'), {
          concurrency: 10
        })
        return next()
      },
      props: (route) => {
        const ancestors = [getCatalogUrl(route)]

        const collectionID = route.params.collectionID
        const url = `${getCatalogUrl(route)}/collections/${collectionID}`

        ancestors.push(url)

        return {
          ancestors,
          path: route.path,
          collectionID,
          sourceID: '',
          endpoint: getCatalogUrl(route),
          url
        }
      }
    },
    {
      path: '/browse/collections/:collectionID/@/:sourceID/items',
      name: 'source',
      beforeEnter: async (to, from, next) => {
        const urls = [
          `${getCatalogUrl(to)}/collections/${to.params.collectionID}`
        ]
        await pMap(urls, store.dispatch.bind(store, 'load'), {
          concurrency: 10
        })
        return next()
      },
      component: Collection,
      props: (route) => {
        const ancestors = [getCatalogUrl(route)]
        const collectionID = route.params.collectionID
        const url = `${getCatalogUrl(route)}/collections/${collectionID}`

        ancestors.push(url)

        return {
          ancestors,
          path: route.path,
          collectionID,
          sourceID: route.params.sourceID,
          endpoint: getCatalogUrl(route),
          url
        }
      }
    },
    {
      path: '/browse/collections/:collectionID/@/:sourceID/items/:itemID',
      name: 'sourceItem',
      component: Item,
      beforeEnter: async (to, from, next) => {
        const urls = [
          `${getCatalogUrl(to)}/collections/${to.params.collectionID}/@/${to.params.sourceID}/items/${
            to.params.itemID
          }`,
          `${getCatalogUrl(to)}/collections/${to.params.collectionID}`
        ]
        await pMap(urls, store.dispatch.bind(store, 'load'), {
          concurrency: 10
        })
        return next()
      },
      props: (route) => {
        const ancestors = [getCatalogUrl(route)]
        const collectionID = route.params.collectionID
        const itemID = route.params.itemID
        const sourceID = route.params.sourceID
        const url = `${getCatalogUrl(route)}/collections/${collectionID}/@/${sourceID}/items/${itemID}`

        ancestors.push(url)

        return {
          ancestors,
          path: route.path,
          collectionID,
          itemID,
          sourceID,
          endpoint: getCatalogUrl(route),
          url
        }
      }
    },
    {
      path: '/browse/collections',
      name: 'home',
      component: Catalog,
      beforeEnter: async (to, from, next) => {
        await store.dispatch('load', `${getCatalogUrl(to)}/collections`)
        return next()
      },
      props: (route) => {
        const ancestors = [getCatalogUrl(route)]

        const url = `${getCatalogUrl(route)}/collections`
        return {
          ancestors,
          path: route.path,
          url
        }
      }
    },
    {
      path: '/(.*)',
      redirect: '/browse/collections'
    }
  ]

  const store = new Vuex.Store({
    modules: {
      auth
    },
    state: {
      entities: {},
      loading: {}
    },
    getters: {
      getEntity: (state) => (uri) => {
        return state.entities[uri]
      }
    },
    mutations: {
      FAILED(state, { err, url }) {
        state.entities = {
          ...state.entities,
          [url]: err
        }

        state.loading = {
          ...state.loading,
          [url]: false
        }
      },
      LOADING(state, url) {
        state.loading = {
          ...state.loading,
          [url]: true
        }
      },
      LOADED(state, { entity, url }) {
        state.entities = {
          ...state.entities,
          [url]: entity
        }

        state.loading = {
          ...state.loading,
          [url]: false
        }
      }
    },
    actions: {
      async load({ commit, state }, url) {
        if (state.entities[url] != null || state.loading[url] === true) {
          // already loading / loaded
          return
        }
        const loggedIn = localStorage.getItem('user')
        if (!loggedIn) {
          return
        }
        commit('LOADING', url)
        try {
          
          const rsp = await fetchUri(url)
          const entity = await rsp.json()

          if (rsp.ok) {
            commit('LOADED', { entity, url })
          } else {
            // disconnect if auth is failing
            if (entity.code === 401) {
              localStorage.removeItem('user')
              location.reload()
            }
            commit('FAILED', {
              err: new Error(entity.message || (await rsp.text())),
              url
            })
          }
        } catch (err) {
          console.warn(err)
          commit('FAILED', { err, url })
        }
      }
    },
    strict: process.env.NODE_ENV !== 'production'
  })

  const router = new VueRouter({
    base: PATH_PREFIX,
    mode: HISTORY_MODE,
    routes
  })

  window.router = router

  router.beforeEach(async (to, from, next) => {
    const loggedIn = localStorage.getItem('user')
    // redirect to login page if not logged in
    if (to.path !== '/login' && !loggedIn) {
      // save params for redirection
      const params = new URLSearchParams(to.query).toString()
      const redirect = to.path + (params.length > 0 ? '?' + params : '')

      return next({ path: '/login', query: { redirect } })
    }

    if (to.path === '/login' && loggedIn) {
      // check and use redirect param if available
      const path = to.query.redirect || '/'
      return next({ path, query: to.query.params })
    }

    if (from.path === to.path) {
      return next()
    }

    if (
      persistedState.path != null &&
      persistedState.path !== to.path.replace(/\/$/, '') &&
      persistedState.path.toLowerCase() === to.path.toLowerCase().replace(/\/$/, '')
    ) {
      return next(persistedState.path)
    }

    // check if hostname is set
    const fEndpoint = from.query.endpoint
    const tEndpoint = to.query.endpoint
    if (fEndpoint && !tEndpoint) {
      const query = Object.assign({}, to.query)
      query.endpoint = fEndpoint

      return next({ path: to.path, query, replace: true })
    }

    return next()
  })

  // initial load
  let el = document.getElementById('app')

  // replace existing content
  if (document.getElementById('rendered') != null) {
    el = document.getElementById('rendered')
  }

  // eslint-disable-next-line no-new
  new Vue({
    el,
    router,
    store,
    template: `<router-view id="rendered" />`,
    data() {
      return {
        map_geometry: undefined
      }
    }
  })
}

main()
