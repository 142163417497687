import User from '../models/user'

export default function authHeader() {
  const userObject = User.loadUser()
  if (userObject) {
    return userObject.header
  } else {
    return {}
  }
}
