import Cookie from 'js-cookie'
import crypto from 'crypto-js'
import uuid from 'uuid'

export default class User {
  get header() {
    return {}
  }

  static loadUser() {
    const stringUser = localStorage.getItem('user')
    const encryptionToken = Cookie.get('browser-auth')
    if (!encryptionToken || !stringUser) {
      localStorage.removeItem('user')
      Cookie.remove('browser-auth')
      return undefined
    }
    const decryptedUser = crypto.AES.decrypt(stringUser, encryptionToken)
    const user = JSON.parse(decryptedUser.toString(crypto.enc.Utf8))
    const userObject = User.hasOwnProperty.call(user, 'token')
      ? Object.assign(new PlatformUser(), user)
      : Object.assign(new LDAPUser(), user)
    return userObject
  }

  static storeUser(user) {
    const stringUser = JSON.stringify(user)
    const encryptionToken = uuid.v4()
    Cookie.set('browser-auth', encryptionToken, { secure: true, expires: 180 })
    const encodedUser = crypto.AES.encrypt(stringUser, encryptionToken).toString()
    localStorage.setItem('user', encodedUser)
  }

  static removeUser() {
    localStorage.removeItem('user')
  }
}

export class LDAPUser extends User {
  constructor(username, password) {
    super()
    this.username = username
    this.password = password
  }

  get header() {
    return {
      Authorization: 'Basic ' + btoa(`${this.username}:${this.password}`)
    }
  }
}

export class PlatformUser extends User {
  constructor(token) {
    super()
    this.token = token
  }

  get header() {
    return { Authorization: 'Bearer ' + this.token }
  }
}
