import { TILE_SOURCE_TEMPLATE, STAC_PROXY_URL, TILE_PROXY_URL, CATALOG_URL } from './config'
import authHeader from './services/auth-header'

class InvalidURLError extends Error {
  constructor(message) {
    super(message)
    this.name = 'InvalidURLError'
  }
}

export const backends = [
  {
    description: 'Catalog Production',
    env: 'catalog production',
    endpoint: 'https://prd-catalog.internal.kayrros.org/api/v2',
    platformAuth: 'prod' // if platform Auth is not specified it will not be listed during login
  },
  {
    description: 'Catalog Staging',
    env: 'catalog staging',
    endpoint: 'https://stg-catalog.internal.kayrros.org/api/v2',
    platformAuth: 'staging'
  },
  {
    description: 'Catalog Development',
    env: 'catalog development',
    endpoint: 'https://dev-catalog.internal.kayrros.org/api/v2',
    platformAuth: 'dev'
  },
  {
    description: 'Catalog Production Public',
    env: 'catalog production public',
    endpoint: 'https://prd-catalog.kayrros.org/api/v2',
    platformAuth: 'prod' // if platform Auth is not specified it will not be listed during login
  },
  {
    description: 'Harvester Development',
    env: 'harvester development',
    endpoint: 'https://dev-harvester.internal.kayrros.org/api/v1'
  },
  {
    description: 'Harvester Staging',
    env: 'harvester staging',
    endpoint: 'https://stg-harvester.internal.kayrros.org/api/v1'
  },
  {
    description: 'Harvester Production',
    env: 'harvester production',
    endpoint: 'https://prd-harvester.internal.kayrros.org/api/v1'
  }
]

export const platformAuthEndpoints = {
  dev: 'https://platform.api.develop.kayrros.org/v1/auth/login',
  staging: 'https://platform.api.develop.kayrros.org/v1/auth/login',
  prod: 'https://auth.kayrros.com/v3/login'
}

export const getCatalogUrl = (route = {}) => {
  const endpoint = route?.query?.endpoint
  if (endpoint) {
    try {
      let url = decodeURIComponent(endpoint)
      if (!(url.startsWith('http://') || url.startsWith('https://'))) {
        url = 'https://' + url
      }
      // eslint-disable-next-line no-new
      new URL(url)
      return url
    } catch (err) {
      console.warn(err)
    }
  }

  return CATALOG_URL
}

export const getProxiedUri = (uri) => {
  // If we are proxying a STAC Catalog, replace any URI with the proxied address.
  // STAC_PROXY_URL has the form https://thingtoproxy.com|http://proxy:111
  return !!STAC_PROXY_URL ? uri.replace(STAC_PROXY_URL.split('|')[0], STAC_PROXY_URL.split('|')[1]) : uri // eslint-disable-line  no-extra-boolean-cast
}

export async function fetchUri(uri) {
  if (!isWhitelistedUri(uri)) {
    throw new InvalidURLError('uri: ' + uri)
  }
  const proxiedUri = getProxiedUri(uri)
  return fetch(proxiedUri, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...authHeader()
    }
  })
}

const getTileProxiedUri = (uri) => {
  // Tile sources can be proxied differently than other assets, replace any asset HREF with the proxied address.
  // Note: This will occur after the STAC_PROXY_URL is used.
  // TILE_PROXY_URL has the form https://thingtoproxy.com|http://proxy:111
  return !!TILE_PROXY_URL ? uri.replace(TILE_PROXY_URL.split('|')[0], TILE_PROXY_URL.split('|')[1]) : uri // eslint-disable-line  no-extra-boolean-cast
}

export const getTileSource = (assetHref) => {
  const proxiedUri = getTileProxiedUri(assetHref)
  return TILE_SOURCE_TEMPLATE.replace('{ASSET_HREF}', proxiedUri)
}

const isWhitelistedUri = (uri) => {
  console.log('[isWhitelistedUri] PATHS: ', uri, typeof uri)
  const paths = uri.split('/')
  if (paths.length < 5) {
    // Issue with the endpoint not valid
    return false
  }
  const protocol = paths[0]
  const host = paths[2]
  const service = paths[3] // api
  const version = paths[4] // v1/v2
  const url = protocol + '//' + host + '/' + service + '/' + version

  let isWhitelisted = false
  for (const value of backends.values()) {
    if (value?.endpoint === url) {
      isWhitelisted = true
      break
    }
  }
  return isWhitelisted
}
