import axios from 'axios'
import User from '../models/user'

class AuthService {
  login(args) {
    return axios
      .get(args.endpoint + '/collections', {
        headers: args.user.header
      })
      .then((response) => {
        if (response.status === 200) {
          User.storeUser(args.user)
        }
        return response.data
      })
  }

  logout() {
    User.removeUser()
  }
}

export default new AuthService()
